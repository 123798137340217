import { Box, Tab, Tabs } from '@mui/material';
import FetchBoundary from 'components/FetchBoundary';
import React from 'react';
import { Link, matchPath, Outlet, useLocation } from 'react-router-dom';

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function SCondPage() {
  const routeMatch = useRouteMatch([
    '/scond/segware-integration/*',
    '/scond/maintance-integrators/*',
  ]);

  const currentTab = routeMatch?.pattern?.path;

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Tabs value={currentTab} sx={{ mb: 2 }}>
        <Tab
          label="Segware Integração ON/PRO"
          value="/scond/segware-integration/*"
          to="/scond/segware-integration"
          component={Link}
        />
        <Tab
          label="Manutenção clientes SCond"
          value="/scond/maintance-integrators/*"
          to="/scond/maintance-integrators"
          component={Link}
        />
      </Tabs>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <FetchBoundary key={currentTab}>
          <Outlet />
        </FetchBoundary>
      </Box>
    </Box>
  );
}
