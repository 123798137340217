/* eslint-disable no-param-reassign */
import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config) => {
  config.baseURL = config.baseURL || BASE_URL;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    return Promise.reject(error);
  }
);
