import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Paper,
  TextField,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { MasterCompany } from 'pages/VMSCloud/types';
import * as yup from 'yup';

interface Props {
  onSave: (values: MasterCompany) => void;
  onBack: () => void;
  initialValues: MasterCompany;
}
function EditCompanyForm({ initialValues, onBack, onSave }: Props) {
  const theme = useTheme();
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      name: yup.string().required('Obrigatório'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await onSave(values);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });
  return (
    <Paper
      sx={{
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Box
        component="form"
        style={{
          width: '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          id="name"
          name="name"
          label="Nome da Master Company"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          margin="normal"
          fullWidth
        />

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(2),
            gap: theme.spacing(2),
          }}
        >
          <Button onClick={onBack}>Voltar</Button>
          <LoadingButton
            loading={formik.isSubmitting}
            variant="contained"
            size="large"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            type="submit"
          >
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
}

export default EditCompanyForm;
