import { useNavigate, useParams } from 'react-router-dom';
import { Container, Header } from '../../components';
import { useVmsAPI } from '../../hook/useVMS';
import { MasterCompany } from '../../types';
import { CompanyEditForm } from '../../forms';
import { useFetch } from 'hooks';

function CompanyEditPage() {
  const { companyId } = useParams<{ companyId: string }>();
  const { data } = useFetch<MasterCompany>(`/api/vms-cloud/company/${companyId}`, {
    cleanCacheAfterMs: 1500,
    
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { updateMasterCompany } = useVmsAPI();
  const navigate = useNavigate();

  const handleSave = async (values: MasterCompany) => {
    await updateMasterCompany(values);
  };

  const handleBack = () => navigate(-1);

  return (
    <Container>
      <Header
        title="Alteração de Master Company"
        subtitle="Permite alterar o nome de qualquer master company cadastrada no VMS Cloud"
      />
      <CompanyEditForm
        onSave={handleSave}
        onBack={handleBack}
        initialValues={data as MasterCompany}
      />
    </Container>
  );
}

export default CompanyEditPage;
